body {
  margin: 0;
  padding: 0;
  max-width: 100%;
  overflow-x: hidden;
  -webkit-user-select: none;
      -moz-user-select: -moz-none;
      -ms-user-select: none;
          user-select: none;
}
* {
  font-family: "Figtree" , -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  font-family: "Figtree", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: "Figtree-Bold";
  src: url('./assets/fonts/Figtree-Bold.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: "Figtree-Medium";
  src: url('./assets/fonts/Figtree-Medium.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: "Figtree-SemiBold";
  src: url('./assets/fonts/Figtree-SemiBold.woff2') format('woff2');
  font-display: swap;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.overflow-hidden {
  overflow: hidden;
}

.display-flex__column {
  display: flex;
  flex-direction: column;
}  

.pac-container:after {
  /* Disclaimer: not needed to show 'powered by Google' if also a Google Map is shown */
  background-image: none !important;
  height: 0px;
}

.switch-wrapper {
  position: relative;
}

.switch-wrapper > div {
  position: absolute;
  width: 100%;
  overflow-x: hidden;
}

#toast-root {
  position: fixed;
  width: 100vw;
  top: 0px;
  z-index: 100;
}

.display-none {
  display: none;
}

#modal-root {
  z-index: 99;
}

#progressInput {
  margin: 20px auto;
  width: 30%;
}

.circle-background,
.circle-progress {
  fill: none;
}

.circle-background {
  stroke: #fff;
}

.circle-progress {
  stroke: #2168f3;
  stroke-linecap: round;
  stroke-linejoin: round;
}

.circle-text {
  font-size: 3em;
  font-family: "Figtree-SemiBold";
  fill: #2168f3;
}

.circle-chart-circle {
  animation: circle-chart-fill 2s reverse;
  transform-origin: center;
}

.animated-bar {
  -webkit-transition: width  0.5s;
  -moz-transition: width   0.5s;
  -o-transition: width   0.5s;
  transition: width  0.5s;
}

@keyframes circle-chart-fill {
  to { stroke-dasharray: 0 100; }
}

#message_container .start-hidden {
  display: none;
}

#message_container .message-option {
  padding: .75em;
  vertical-align: middle;
}

#message_container {
  background: linear-gradient(30deg, #1916b9 0%, #2955e4 30%, #2168f3 75%, #2168f3 100%);
  color: #f6f8f9;
  font-size: 13px;
  height: 100%;
  text-align: center;
  width: 100%;
}

.react-datepicker-wrapper {
  width : 100%;
}

@keyframes rotate {
  0% {
    transform: rotateX(0deg);
  }
  12.5% {
    transform: rotateX(90deg);
  }
  25%{
    transform: rotateX(90deg);
  }
  37.5% {
    transform: rotateX(180deg);
  }
  50% {
    transform: rotateX(180deg);
    
  }
  62.5% {
    transform: rotateX(270deg);
  }
  75% {
    transform: rotateX(270deg);
  }
  87.5% {
    transform: rotateX(360deg);
  }
  100% {
    transform: rotateX(360deg);
  }
}

.vertical-rotator {
  display: inline-block;
  color: #e74c3c;
  position: relative;
  white-space: nowrap;
  top: 0;
  left: 0;
  animation: move 6s;
  animation-iteration-count: infinite;
  animation-delay: 0s;
}

#reward-box-container {
  animation: moveAndChangeBorderColor 6s infinite;
  animation-delay: 0s;
}

@keyframes moveAndChangeBorderColor {
  0%, 25%  { border-color: red; }
  50%, 75% { border-color: rgba(229, 232, 240, 1); }
  100% { border-color: red; }
}

@keyframes move {
  0%, 25%  { top: 0px; }
  50%, 75% { top: -35px; }
  100% { top: 0px; }
}

.wearable-animation {
  transform-style: preserve-3d;
  animation: rotate 16s ease-in infinite;
}

@keyframes heightChange {
  from {
    height: 0;
  }
  to {
    height: var(--height);
  }
}

.height-change {
  animation: heightChange 0.5s ease-in-out forwards;
}

.custom-scrollbar {
  scrollbar-width: none; /* For Firefox */
}

.custom-scrollbar::-webkit-scrollbar {
  display: none; /* For Chrome, Safari, and Opera */
}

.two-line-truncate {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
}