@tailwind base;
@tailwind components;
@tailwind utilities;

img {
    display: inline;
}

table.alternate_gray200_color tr:nth-child(even) {
    background-color: rgba(245, 248, 255, 0.5);
}